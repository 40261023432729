<template>
    <st-page :title="$t('APPLICATION.PAYMENT_ORDER.TITLE')">
        <Loader v-if="isLoading" />
        <template #toolbar>
            <st-button
                :callback="savePaymentOrderDocument"
                :spinner="isLoadingSave"
                variant="primary"
            >
                <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
            </st-button>
            <st-button
                :callback="cancelPaymentOrderDocument"
                :spinner="isLoadingCancel"
                variant="primary"
            >
                <span>{{ $t("APPLICATION.PAYMENT_ORDER.CANCEL") }}</span>
            </st-button>
        </template>
        <st-section :header="$t('APPLICATION.PAYMENT_ORDER.SECTION_TITLE')">
            <template #body>
                <form class="form" novalidate="novalidate" id="st-op-form">
                    <div class="row">
                        <div class="col col-12 col-lg-4">
                            <div class="form-group">
                                <label>
                                    {{ fields.order_number.label }} *
                                </label>
                                <st-input-text
                                    v-model="model[fields.order_number.name]"
                                    :ref="fields.order_number.name"
                                    :name="fields.order_number.name"
                                />
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.payment_date.label }} *
                                </label>
                                <st-datepicker
                                    v-model="model[fields.payment_date.name]"
                                    :name="fields.payment_date.name"
                                    :ref="fields.payment_date.name"
                                    @change="
                                        validateField(fields.payment_date.name)
                                    "
                                >
                                </st-datepicker>
                            </div>
                            <div class="form-group">
                                <label> {{ fields.file.label }} * </label>

                                <st-file
                                    :maxFiles="1"
                                    :acceptedFileTypes="[
                                        'image/jpeg',
                                        'image/png',
                                        'application/pdf',
                                    ]"
                                    :isValid="isValid"
                                    :wasValidated="wasValidated"
                                    :disabled="disableFileUpload"
                                    @change="uploadPaymentOrderDocument"
                                >
                                </st-file>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </st-section>
    </st-page>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import Message from "@/shared/message/message";

import { FormSchema } from "@/shared/form/form-schema";
import { PaymentOrderModel } from "../models/payment-order-model";
const { fields } = PaymentOrderModel;

const formSchema = new FormSchema([
    fields.op_payment_id,
    fields.order_number,
    fields.payment_date,
    fields.file,
]);

export default {
    name: "ApplicationPaymentOrder",
    props: {
        opPaymentId: {
            type: String,
            required: true,
        },
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            uploadedDocuments: [],
            isValid: false,
            wasValidated: false,
            fv: null,
            model: formSchema.initialValues({}),
            fields,
            rules: formSchema.rules(),
            isLoading: false,
            isLoadingSave: false,
            isLoadingCancel: false,
        };
    },
    computed: {
        ...mapGetters({
            uploadAccessToken: "applications/payment/uploadAccessToken",
        }),
        disableFileUpload() {
            return !this.uploadAccessToken;
        },
    },
    isLoadingSave() {
        return this.loading["applications/payments/savePaymentOrder"];
    },
    isLoadingCancel() {
        return this.loading["applications/payments/cancelPaymentOrder"];
    },
    methods: {
        ...mapActions({
            doUpload: "applications/form/upload",
            doUpdate: "applications/form/update",
            doFind: "applications/form/find",
            doSavePaymentOrder: "applications/payment/savePaymentOrder",
            doCancelPaymentOrder: "applications/payment/cancelPaymentOrder",
            getPaymentOrderToken: "applications/payment/getUploadToken",
        }),
        validateField(field) {
            this.fv.validateField(field);
        },
        validateFileField() {
            this.wasValidated = true;
            this.isValid = !!this.uploadedDocuments?.length;
        },
        uploadPaymentOrderDocument(documents) {
            this.uploadedDocuments = [];

            if (this.opPaymentId && documents?.length) {
                for (let i = 0; i < documents?.length; i++) {
                    this.uploadedDocuments.push(documents[i]);
                }
            }
            this.validateFileField();
        },
        async savePaymentOrderDocument() {
            this.wasValidated = true;
            const validate = await this.fv.validate();
            if (validate !== "Valid" || !this.isValid) return false;
            this.isLoadingSave = true;
            this.isLoading = true;
            const formData = new FormData();

            for (let i = 0; i < this.uploadedDocuments?.length; i++) {
                const file = this.uploadedDocuments[i];
                if (file instanceof File) {
                    formData.append("files", file);
                }
            }
            this.doUpload({
                files: formData,
                uploadAccessToken: this.uploadAccessToken,
            })
                .then((uploadedFiles) => {
                    const attachedFiles = uploadedFiles?.map((el) => ({
                        file_id: el.id,
                        filename: el.initialFilename,
                        created_date: el.created_date,
                    }));

                    const payload = {
                        op_payment_id: this.model.op_payment_id,
                        file: attachedFiles[0].file_id,
                        order_number: this.model.order_number,
                        payment_date: this.model.payment_date,
                    };

                    this.doSavePaymentOrder(payload)
                        .then(({ redirectUrl }) => {
                            window.location.replace(redirectUrl);
                            this.isLoadingSave = true;
                        })
                        .finally(() => {
                            this.isLoading = false;
                            this.isLoadingSave = false;
                        });
                })
                .catch(() => {
                    this.isLoading = false;
                    this.isLoadingSave = false;
                });
        },
        async cancelPaymentOrderDocument() {
            this.isLoadingCancel = true;
            const payload = {
                        op_payment_id: this.model.op_payment_id,
                    };

             this.doCancelPaymentOrder(payload)
                .then(() => {
                    this.$alert({
                        type: 'success',
                        title: this.$t('APPLICATION.NOTIFIERS.OP_CANCELLED_SUCCESS'),
                        text: this.$t('APPLICATION.NOTIFIERS.OP_CANCELLED_SUCCESS_TEXT'),
                        isTextHtml: true,
                        cancelButtonText: this.$t('GENERAL.CLOSE'),
                        showCancelButton: false,
                        showConfirmButton: true,
                        callback: () => {
                            this.$router.push({
                                name: 'applicationEdit',
                                params: {
                                    id: this.applicationId,
                                },
                            }); 
                        },
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                    this.isLoadingCancel = false;
                });
        },
    },
    mounted() {
        this.fv = createFormValidation("st-op-form", this.rules);
    },
    async created() {
        await this.getPaymentOrderToken({ id: this.applicationId });
        this.model = formSchema.initialValues({
            [fields.op_payment_id.name]: this.opPaymentId,
        });
    },
};
</script>