import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import IdField from '@/shared/fields/id-field';

const label = (name) => i18n.t(`APPLICATION.PAYMENT_ORDER.FIELDS.${name.toUpperCase()}`);

const fields = {
    op_payment_id: new IdField('op_payment_id', 'Id'),
    order_number: new StringField(
        'order_number', 
        label('order_number'), 
        { required: true }),
    payment_date: new DateField(
        'payment_date', 
        label('payment_date'), 
        { required: true }),
    file: new IdField('file', label('file')),
};

export class PaymentOrderModel extends GenericModel {
    static get fields() {
        return fields;
    }
}